import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGitlab,
  faLinkedin,
  faTwitter,
  faInstagram,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";

class Footer extends React.Component {
  render() {
    const FooterMain = styled.div`
      background-color: #111;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    `;

    const SocialIcons = styled.div`
      .social_icon {
        font-size: 15px;
        color: #555;
        margin: 0 5px;
        cursor: pointer;
        transition: 0.5s;
        &:hover {
          color: #04e5e5;
        }
      }
    `;
    return (
      <FooterMain>
        <SocialIcons>
          <FontAwesomeIcon
            icon={faGitlab}
            className="social_icon"
            onClick={() => window.open("https://gitlab.com/manuel.morandin")}
          />
          <FontAwesomeIcon
            icon={faTwitter}
            className="social_icon"
            onClick={() => window.open("https://twitter.com/manuel_mora97")}
          />
          <FontAwesomeIcon
            icon={faInstagram}
            className="social_icon"
            onClick={() => window.open("https://www.instagram.com/mora2297/")}
          />
          <FontAwesomeIcon
            icon={faFacebook}
            className="social_icon"
            onClick={() =>
              window.open("https://www.facebook.com/manuel.morandin.3/")
            }
          />
          <FontAwesomeIcon
            icon={faLinkedin}
            className="social_icon"
            onClick={() =>
              window.open(
                "https://www.linkedin.com/in/manuel-morandin-6b651314a/"
              )
            }
          />
        </SocialIcons>
      </FooterMain>
    );
  }
}
export default Footer;
