import React from "react";
import styled, { keyframes } from "styled-components";
import Progress from "components/progress";
import Timeline from "sections/about/parts/Timeline";

class TabsPart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "skills",
    };
  }

  render() {
    const TabContainer = styled.div`
      min-height: 400px;
      margin-top: 20px;
      @media (max-width: 767px) {
        margin-top: 50px;
        padding: 0 20px;
      }
    `;
    const TabSelectors = styled.div`
      display: flex;
    `;

    const ColorAnimation = keyframes`
            0%  {border-color: #04e5e5;}
            10% {border-color: #f37055;}
            20% {border-color: #ef4e7b;}
            30% {border-color: #a166ab;}
            40% {border-color: #5073b8;}
            50% {border-color: #04e5e5;}
            60% {border-color: #07b39b;}
            70% {border-color: #6fba82;}
            80% {border-color: #5073b8;}
            90% {border-color: #1098ad;}
            100% {border-color: #f37055;}
        `;
    const TabSelector = styled.button`
      color: #fff;
      font-size: 20px;
      font-weight: bold;
      background-color: transparent;
      border: none;
      margin: 0 10px 0 0;
      border-bottom: 2px solid #fff;
      transition: 0.5s;
      &:hover,
      &.active {
        animation: ${ColorAnimation} 10s infinite alternate;
      }
      &:focus {
        outline: none;
      }
      @media (max-width: 767px) {
        font-size: 18px;
      }

      @media (max-width: 320px) {
        font-size: 14px;
      }
    `;

    const Tabs = styled.div`
      margin-top: 20px;
    `;

    const Fade = keyframes`
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        `;

    const Tab = styled.div`
      display: none;
      animation: ${Fade} 1s forwards;
    `;

    return (
      <TabContainer>
        <TabSelectors>
          <TabSelector
            className={this.state.tab === "skills" ? "active" : ""}
            onClick={() => this.setState({ tab: "skills" })}
          >
            Skills
          </TabSelector>
          <TabSelector
            className={this.state.tab === "experience" ? "active" : ""}
            onClick={() => this.setState({ tab: "experience" })}
          >
            Experience
          </TabSelector>
          <TabSelector
            className={this.state.tab === "education" ? "active" : ""}
            onClick={() => this.setState({ tab: "education" })}
          >
            Education
          </TabSelector>
        </TabSelectors>
        <Tabs>
          <Tab
            style={{
              display: this.state.tab === "skills" ? "block" : "none",
            }}
          >
            <Progress value={90} text="Javascript" />
            <Progress value={80} text="React" />
            <Progress value={80} text="HTML/CSS" />
            <Progress value={70} text="C#" />
            <Progress value={60} text="MySQL" />
          </Tab>
        </Tabs>
        <Tabs>
          <Tab
            style={{
              display: this.state.tab === "experience" ? "block" : "none",
            }}
          >
            <Timeline
              data={{
                "2015 - 2015": {
                  title: "Junior Systemist",
                  institution: "Lotto S.P.A.",
                  description:
                    "As a junior systems engineer, I had the opportunity to learn the functioning and internal dynamics of a large corporate network.",
                },
                "2018 - 2018": {
                  title: "Full-stack web developer",
                  institution: "Discontinuity",
                  description:
                    "As a full-stack developer in Discontinuity, I started learning about the web and app world, through the development of different projects, especially through the use of react and react-native.",
                },
                "2019 - 2020": {
                  title: "Full-stack developer",
                  institution: "AD System",
                  description:
                    "As a full-stack developer in Ad-System, I learned the world of Microsoft desktop software. Through the .Net framework I have developed in C # and with different programming patterns, varying from WPF to MVVM.",
                },
                "2020 - Now": {
                  title: "Full-stack web developer",
                  institution: "SixLab",
                  description:
                    "As a full-stack developer in SixLab, I continue to apply myself in different web and app languages, from Laravel to React, to React to Kotlin and many others.",
                },
              }}
            />
          </Tab>
          <Tab
            style={{
              display: this.state.tab === "education" ? "block" : "none",
            }}
          >
            <Timeline
              data={{
                "2011 - 2017": {
                  title: "High School Degree",
                  institution: "IIS Einaudi - Scarpa",
                  description:
                    "I learned the basics of computer science, which allow me and which will allow me to learn everything I know and what I will know in the future.",
                },
                // "2018 - 2018" : {
                //     title: "Web and app developer certificate",
                //     institution: "Adatta formazione",
                //     description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting"
                // },
                "2019 - Now": {
                  title: "Full stack developer",
                  institution: "Start2Impact",
                  description:
                    "I'm doing a few different courses, but the most important is the full-stack developer course, the others are the UI / UX designer courses and the digital marketing course.",
                },
              }}
            />
          </Tab>
        </Tabs>
      </TabContainer>
    );
  }
}

export default TabsPart;
