import React from "react";
import "./styles.scss";
//Styeld Component does not support @for loops

class Glitch extends React.Component {
  render() {
    const { text } = this.props;
    return (
      <h1 className="glitch" data-text={text}>
        {text}
      </h1>
    );
  }
}
export default Glitch;
