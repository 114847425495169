import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import Layout from "components/layout";
import HeroPersonal from "sections/hero/HeroPersonal.js";
import AboutPersonal from "sections/about/AboutPersonal.js";
import ServicesThree from "sections/services/ServicesThree.js";

import TestimonialsOne from "sections/testimonials/TestimonialsOne.js";

import ContactPersonal from "sections/contact/ContactPersonal.js";
// import i18n from "./i18n";

//&gestire la lingua e la dinamicità

class Index extends React.Component {
  render() {
    const { site } = this.props.data;

    return (
      <div>
        <Helmet>
          <html lang="en" />
          <title>{site.meta.title}</title>
          <meta charset="UTF-8" />
          <meta name="description" content={site.meta.description} />
          <meta
            name="keywords"
            content="moracodes,manuel,morandin,manuel morandin,morandin manuel,web developer,developer,freelance,web developer freelance,app,hybrid app,web site,ios,iOS,IOS,react,react-native,react native,web app,about me,my services,email,phone"
          />
          <meta name="author" content="Manuel Morandin" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.mrwebmaster.it/" />
          <meta property="og:site_name" content="MoraCodes" />
          <meta property="og:title" content={site.meta.title} />
          <meta
            property="og:image"
            content="https://www.mrwebmaster.it/copertina-grande.jpg"
          />
          <meta property="og:description" content={site.meta.description} />
          <meta property="og:locale" content="it_IT" />

          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="@manuel_mora97" />
          <meta name="twitter:title" content={site.meta.title} />
          <meta name="twitter:description" content={site.meta.description} />
          <meta name="twitter:creator" content="@manuel_mora97" />
          <meta
            name="twitter:image"
            content="http://www.example.com/image.jpg"
          />
          <script
            src="//code.tidio.co/trakkceslqlrdkyhf0yg95ncknpnxlzp.js"
            async
          ></script>
          <link rel="canonical" href="https://www.moracodes.dev/"></link>
        </Helmet>
        <Layout
          isHome={true}
          sections={["home", "about", "services", "contact"]}
        >
          <HeroPersonal />
          <AboutPersonal />
          <ServicesThree />
          <TestimonialsOne />
          <ContactPersonal />
        </Layout>
      </div>
    );
  }
}

export default Index;

export const personalQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`;
